<template>
  <div :key="componentKey">
    <v-row justify="center" style="margin-top: 15px;">
      <h1 class="mb-3 text-center">Reports</h1>
    </v-row>

    <v-container class="text-center">
      <v-row align="center" justify="center" cols="">
        <v-col cols="12" sm="6" md="2" lg="2">
          <span>Partner</span>
          <model-list-select :list="partners"
                      v-model="finder.partner"
                      option-value="nome"
                      option-text="nome"
                      placeholder="select item"
                      style="height: 35px;">
          </model-list-select>
        </v-col>
        
        <v-col cols="12" sm="6" md="2" lg="2">
          <span>Situation</span>
          <model-list-select :list="options"
                      v-model="finder.situation"
                      option-value="value"
                      option-text="value"
                      placeholder="select item"
                      style="height: 35px;">
          </model-list-select>
        </v-col>

        <v-col cols="12" sm="6" md="2" lg="2">
          <span>Start date</span> <br>
          <date-picker v-model="finder.startDate" valueType="format" style="width: inherit;"></date-picker>
        </v-col>

        <v-col cols="12" sm="6" md="2" lg="2">
          <span>End date</span> <br>
          <date-picker v-model="finder.endDate" valueType="format" style="width: inherit;"></date-picker>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-btn style="margin-top: 22px; margin-right: 12px;" depressed color="primary" v-on:click="executeSearch(finder)">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>

          <v-btn title="New report" style="margin-top: 22px; margin-right: 12px;" v-on:click="createReportProps.dialogOpened = true" v-if="loans.length" depressed>
              +
              <v-icon>
                  mdi-file-document
              </v-icon>
          </v-btn>

          <v-btn style="margin-top: 22px;" v-on:click="$router.push('/emitted-reports')" title="See emitted reports" depressed>
              <v-icon>
                  mdi-file-check
              </v-icon>
          </v-btn>
        </v-col> 
      </v-row>
    </v-container>
    
    <v-row v-if="isLoading" justify="center" no-gutters class="my-12">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-row>

    <v-row v-if="!loans.length && !isLoading" justify="center" no-gutters class="my-12">
      <v-alert dense type="info">
        Not found any loans with the select filters.
      </v-alert>
    </v-row>

    <v-container id="printer-wrapper">
      <v-row v-if="showFilters" justify="center" style="margin-top: 20px;">
        <div class="text-center" style="display: flex; justify-content: space-around;">
          <v-col cols="12" sm="8" md="2" lg="2">
            <span><h4>Partner: {{ finder.partner }}</h4></span> <br>
          </v-col>
          <v-col cols="12" sm="8" md="2" lg="2">
            <span><h4>Situation: {{ finder.situation }}</h4></span> <br>
          </v-col>
          <v-col cols="12" sm="8" md="2" lg="2">
            <span><h4>Start date: {{ finder.startDate }}</h4></span> <br>
          </v-col>
          <v-col cols="12" sm="8" md="2" lg="2">
            <span><h4>End date: {{ finder.endDate }}</h4></span> <br>
          </v-col>
          <v-col cols="12" sm="8" md="2" lg="2" v-if="comentario">
            <span><h4>Observation: {{ comentario }}</h4></span> <br>
          </v-col>
        </div>
      </v-row>

      <v-row justify="center" class="table table-bordered" style="margin-top: 10px;">
        <v-simple-table
          dense
          v-if="!isLoading && loans.length"
          style="width: 100%;"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="col" class="text-center">Partner</th>
                <th scope="col" class="text-center">Stock</th>
                <th scope="col" class="text-center">#</th>
                <th scope="col" class="text-center">Days</th>
                <th scope="col" class="text-center" style="min-width: 120px;">Due date</th>
                <th scope="col" class="text-center" >Interest</th>
                <th scope="col" class="text-center">Principal</th>
                <th scope="col" class="text-center">Payment</th>
                <th scope="col" class="text-center">Principal payment</th>
                <th scope="col" class="text-center">Principal balance</th>
                <th scope="col" class="text-center" style="min-width: 100px;">Status</th>
                <th scope="col" class="text-center" style="min-width: 120px;">Paid date</th>
                <th scope="col" class="text-center">
                  Pay 
                  <input class="input-pay" type="checkbox" v-on:click="checkAll()" style="margin-left: 10px;" v-model="isCheckAll">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="loan in loans"
                :key="loan.id"
              >
                <td class="text-center"><a v-on:click="loadPartner(loan.contrato.banco.id)">{{ loan.contrato.banco.nome }}</a></td>
                <td class="text-center">
                  <a v-if="loan.situacao != 'DEACTIVADED'" v-on:click="loadLoan(loan.contrato.id)">
                    {{ loan.contrato.stockId }}
                  </a>
                  <span v-else>
                    {{ loan.contrato.stockId }}
                  </span>
                </td>
                <td class="text-center">{{ loan.nroParcela }}/{{ loan.contrato.qtdeParcelas }}</td>
                <td class="text-center">{{ loan.diasPrimeiraParcela || loan.diasProximaParcela }}</td>
                <td class="text-center">{{ loan.dataPagamento }}</td>
                <td class="text-center">{{ loan.vlParcelaJuros | toCurrency }}</td>
                <td class="text-center">{{loan.vlParcela + loan.balanceAfterPayment | toCurrency}}</td>
                <td class="text-center">{{loan.vlParcela | toCurrency}}</td>
                <td class="text-center">{{ loan.vlParcela - loan.vlParcelaJuros | toCurrency}}</td>
                <td class="text-center">{{ loan.balanceAfterPayment | toCurrency}}</td>
                <td class="text-center"><v-btn x-small :color="defineColor(loan)">{{ loan.contrato.paidWithPayOff ? 'Pay-off' : loan.situacao }}</v-btn></td>
                <td class="text-center">
                    {{loan.dataPagamentoPaga}}
                </td>
                <td class="text-center" style="min-width: 120px;">
                  <span v-if="loan.situacao === 'UNPAID' && loan.lastLoanPaid">
                    <input class="input-pay" v-bind:value='loan' type="checkbox" v-model="loansList" @change="updateCheckAll()">
                    Pay
                  </span>
                </td>
              </tr>
              <tr>
                <td class="text-center"></td>
                <td class="text-center"><strong>Total Loans: </strong>{{loans.length}}</td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"> <strong>T.Interest:</strong> {{totals.totalInterests | toCurrency}}</td>
                <td class="text-center"></td>
                <td class="text-center"><strong>T.Payments:</strong> {{totals.totalPayments | toCurrency}}</td>
                <td class="text-center"><strong>T.Principal</strong>{{totals.totalPrincipalPayments | toCurrency}}</td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center" style="width: 120px;">
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row style="margin-top: 5px; text-align: center;" align="center" justify="center" v-if="loans.length && !isLoading">     
          <v-col sm="3" v-if="loansList.length">
            <v-text-field
                label="Observation"
                v-model="comentario"
            ></v-text-field>
            <v-btn depressed color="primary" v-on:click="bulkPay(loansList, comentario)">
              Bulk pay
            </v-btn>
          </v-col>  
        </v-row>
      </v-row>
    </v-container> 

     <v-dialog v-model="createReportProps.dialogOpened" transition="dialog-top-transition" max-width="600">
        <CreateReport 
          :filtros="finder" 
          :itens="loans" 
          :totals="totals"
          :type="'P'"
          @close-dialog="createReportProps.dialogOpened = false"/>
     </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { ModelListSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import ParcelaService from "../services/ParcelaService";
import PartnerService from "../../partners/services/PartnerService";
import _ from 'lodash';
import LoanService from '../../loans/services/LoanService';
import CreateToast from '../../../utils/createToast';
import CreateReport from './components/CreateReport.vue';

export default {
  name: "Home",
  components: {
    ModelListSelect,
    DatePicker,
    CreateReport
  },
  data: () => ({
    loans: [],
    partners: [{ nome: 'All' }],
    partner: {},
    finder: { 
        startDate: moment().format('YYYY-MM-DD'), 
        endDate: moment().format('YYYY-MM-DD'),
        situation: "All",
        partner: "All"
    },
    isLoading: false,
    options: [
      { id: 1, value: 'All' },
      { id: 2, value: 'UNPAID' },
      { id: 3, value: 'PAID' },
      { id: 4, value: 'DEACTIVADED' }
    ],
    loansList: [],
    totals: {
      totalPayments: 0.0,
      totalInterests: 0.0,
      totalPrincipalPayments: 0.0,
    },
    comentario: "",
    componentKey: 0,
    isCheckAll: false,
    showFilters: false,
    createReportProps: {
      dialogOpened: false
    }
  }),
  created() {
    this.init()
  },

  methods: {
    async init() {
      this.isLoading = true;
      this.loans = [];
      this.partners = [{ nome: 'All' }];
      this.componentKey + 1;
      this.populateFiltersAndResults();
      PartnerService.findAll().then(response => {
        response.data.map(result => {
          this.partners.push(result);
        });
        this.partners = this.partners.filter(partner => partner.situacao != 'DEACTIVADED').sort();
      }).catch(err => {
        CreateToast.createToastFailed('An error ocurred! Please try again!');
      }).finally(() => {
        this.isLoading = false;
      });
    },

    async executeSearch(finder) {
      this.isLoading = true;
      ParcelaService.buscaAvancada(
          finder.partner, 
          finder.situation, 
          finder.startDate, 
          finder.endDate
      ).then((response) => {
          this.loans = response.data;
          this.totals.totalPayments = _.sumBy(this.loans, 'vlParcela');
          this.totals.totalInterests = _.sumBy(this.loans, 'vlParcelaJuros')
          var valorTotalParcelaSemJuros = 0.0;
          this.loans.forEach(loan => {
            valorTotalParcelaSemJuros += (loan.vlParcela - loan.vlParcelaJuros);
          })

          this.totals.totalPrincipalPayments = valorTotalParcelaSemJuros;
      }).finally(() => {
          this.saveResultsToLocalStorage();
          this.isLoading = false;
          this.comentario = "";
      }); 
    },

    loadPartner(idBanco) {
      this.$router.push(`/partners/${idBanco}`);
    },

    loadLoan(idLoan) {
      this.$router.push(`/loans/${idLoan}`);
    },

    checkAll() {
      this.isCheckAll = !this.isCheckAll;
      this.loansList = [];
      var filter = _.filter(this.loans, (parcela) => { return parcela.situacao == 'UNPAID' && parcela.lastLoanPaid })
      if(this.isCheckAll){
          for (var key in filter) {
              console.log(key)
              this.loansList.push(filter[key]);
          }
      }
    },

    updateCheckAll() {
      var filter = _.filter(this.loans, (parcela) => { return parcela.situacao == 'UNPAID' && parcela.lastLoanPaid })
      if(this.loansList.length == filter.length){
          this.isCheckAll = true;
      }else{
          this.isCheckAll = false;
      }
    },

    bulkPay(loansList, comentario) {
      if (this.checkMoreThanOnePartner(loansList)) {
        this.isLoading = true;
        CreateToast.createToastInfo('Payment in process!', 'Sending emails to partners to verify your paid loans.');

        let nrosParcelas = [];
        let idsContratos = [];

        loansList.forEach(loan => {
          nrosParcelas.push(loan.nroParcela);
          idsContratos.push(loan.idContrato);
        })
        
        LoanService.pay(idsContratos, nrosParcelas, moment().format("YYYY-MM-DD"), false, comentario).then(response => {
          CreateToast.createToastSuccess('Payments processed with success.');
        }).then(() => {
          this.loansList = [];
          this.executeSearch(this.finder);
        }).catch(err => {
          CreateToast.createToastFailed('An error ocurred! Please try again!');
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },

    checkMoreThanOnePartner(loanList) {
      let newList = _.uniqBy(loanList, (loan) => {
        return loan.contrato.banco.id;
      });

      if (newList.length > 1) {
        CreateToast.createToastWarning(`It's not possible pay two different partners at the same time. Please, use filters to find and pay separately!`);
        return false;
      }

      return true;
    },

    print() {
      this.showFilters = true;
      setTimeout(() => {
        this.$htmlToPaper('printer-wrapper');
       
        this.showFilters = false;
      }, 500)
    },
    
    defineColor(loan) {
      if (loan.situacao == 'UNPAID') {
            return 'default';
        }

        if (loan.situacao == 'DEACTIVADED') {
            return 'error'
        }

        if (loan.situacao == 'PAID' && !loan.contrato.paidWithPayOff) {
            return 'success'
        }

        return 'primary'
    },

    saveResultsToLocalStorage() {
      var filters = _.cloneDeep(this.finder);
      var results = _.cloneDeep(this.loans);
      var totais = _.cloneDeep(this.totals);
      window.localStorage.setItem('beforeFiltersLoans', JSON.stringify(filters));
      window.localStorage.setItem('beforeResultsLoans', JSON.stringify(results));
      window.localStorage.setItem('beforeTotalsLoans', JSON.stringify(totais));
    },

    populateFiltersAndResults() {
      var filters = JSON.parse(window.localStorage.getItem('beforeFiltersLoans'));
      var results = JSON.parse(window.localStorage.getItem('beforeResultsLoans'));
      var totais = JSON.parse(window.localStorage.getItem('beforeTotalsLoans'));

      if (!_.isEmpty(filters)) {
        this.finder = filters;
      }

      if (!_.isEmpty(results)) {
        this.loans = results;
      }

      if (!_.isEmpty(totais)) {
        this.totals = totais;
      }
    },
  }
};
</script>

<style scoped>
  a {
    text-decoration: none;
  }
</style>